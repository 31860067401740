/**
 * @module dateConverter
 */

/**
 * @function zeroPad
 * @description pads a number with zeros..
 * @param {number} num the number
 * @param {number} places how many total places
 * @returns {string}
 */
const zeroPad = function (num, places) {
    let zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
}

/**
 * @function getWeekNumber
 * @description Calcultaes the week number for the fiven Date (javascript Date())
 * <strong>ATTENTION!</strong> function expects a Date() format. so remember to call getWeekNumber(new Date(date)) if it isnt one.
 *
 * @param {Date} date
 * @returns {number}
 */
function getWeekNumber(date) {
    // ATTENTION! function expects a Date() format. so remember to call getWeekNumber(new Date(date)) if it isnt one.
    if (!(date instanceof Date)) date = new Date();

    // ISO week date weeks start on Monday, so correct the day number
    const nDay = (date.getDay() + 6) % 7;

    // ISO 8601 states that week 1 is the week with the first Thursday of that year
    // Set the target date to the Thursday in the target week
    date.setDate(date.getDate() - nDay + 3);

    // Store the millisecond value of the target date
    const n1stThursday = date.valueOf();

    // Set the target to the first Thursday of the year
    // First, set the target to January 1st
    date.setMonth(0, 1);

    // Not a Thursday? Correct the date to the next Thursday
    if (date.getDay() !== 4) {
        date.setMonth(0, 1 + ((4 - date.getDay()) + 7) % 7);
    }

    // The week number is the number of weeks between the first Thursday of the year
    // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
    return 1 + Math.ceil((n1stThursday - date) / 604800000);
}

/**
 * @function dateConv
 * @description myDateTime must be in Date() format
 *  use mode = 0 normal for normal date conversion : returns dd-mm-yy
 *             1 american for american conversion : returns yy-mm-dd
 * @param {Date} myDateTime the date in javascript Date() format
 * @param {number} mode see comment for options
 * @returns {string}
 */
const dateConv = function (myDateTime, mode= 0){
    const myDate = myDateTime;
    let [month, day , year] = [myDate.getMonth() + 1, myDate.getDate(), myDate.getFullYear()];
    month = zeroPad(month, 2);
    day = zeroPad(day, 2);
    if (mode === 0) return `${day}-${month}-${year}`;
    else if (mode === 1) return `${year}-${month}-${day}`;
};

/**
 * @function specialDateConv
 * @description Converts a date string (MM-DD) to european format (DD-MM)
 *              Or from european to american format
 *              Also works for YYYY-MM-DD to DD-MM-YYYY
 *
 * @param {string} myDateString the date string in month-day format. IMPORTANT!
 * @returns returns date string in european format. (dd-mm)
 */
const specialDateConv = function (myDateString) {
    const dateStr = myDateString.split("-");
    if (dateStr.length === 3) return `${dateStr[2]}-${dateStr[1]}-${dateStr[0]}`;
    else return `${dateStr[1]}-${dateStr[0]}`;
}

/**
 * @function paddedDateConv
 * @description Pads a date string to the correct format.
 *              The string will be formatted according to the provided locale. (defaults to 'en')
 *              it can be MM-DD, YYYY-MM-DD, (en)
 *              or DD-MM-YYYY, DD-MM (nl)
 *
 * @param {string} dateStr  the date string to be formatted
 * @param {string} locale the locale in which the date should be formatted. ('en' or 'nl')
 * @returns {string} the formatted date string
 */
const paddedDateConv = function (dateStr, locale = 'en') {
    let day, month, year;
    const dateArr = dateStr.split("-");
    if (dateArr.length === 3) {
        [year, month, day] = [dateArr[0], dateArr[1], dateArr[2]];
        if (year.length === 2) {
            year = `20${year}`;
        }
        if (locale === 'en') {
            return `${year}-${zeroPad(month, 2)}-${zeroPad(day, 2)}`;
        } else {
            return `${zeroPad(day, 2)}-${zeroPad(month, 2)}-${year}`;
        }
    } else {
        [month, day] = [dateArr[0], dateArr[1]];
        if (locale === 'en') {
            return `${zeroPad(month, 2)}-${zeroPad(day, 2)}`;
        } else {
            return `${zeroPad(day, 2)}-${zeroPad(month, 2)}`;
        }
    }
}


/**
 * @function timeConv
 * @description Converts the given javascript Date() to the TIME only
 *              use mode = 0 normal for normal conversion : return hh:mm (default)
 *                         1 scientific for scientific conversion : return hh:mm:ss
 * @param {Date} myDateTime js Date() date
 * @param {number} mode see comment
 * @returns {string}
 */
const timeConv = function (myDateTime, mode = 0) {
    if (myDateTime) {

        let [hour, minute, second] = [zeroPad(myDateTime.getHours(), 2), zeroPad(myDateTime.getMinutes(), 2), zeroPad(myDateTime.getSeconds(), 2)];
        if (mode === 0) return `${hour}:${minute}`;
        else if (mode === 1) return `${hour}:${minute}:${second}`;
    } else return "";
}

/**
 * @function timeStrConv
 * @description Converts a time STRING to a formatted time string
 *              use mode = 0 for returning normal : hh:mm (default)
 *              1 for returning scient : hh:mm:ss
 *              if no seconds are provided in scientific mode, zeros are added.
 *              valid time strings are : HH:MM, HH.MM, HHMM
 * @param {string} myTimeStr time string "HH:MM" or "HH:MM:SS"
 * @param {number} mode see comment
 * @returns {string}
 */
const timeStrConv = function (myTimeStr, mode = 0) {
    if (myTimeStr) {
        let time;
        if (myTimeStr.indexOf(":") !== -1){
            time = myTimeStr.split(":");
        } else if (myTimeStr.indexOf(".") !== -1){
            time = myTimeStr.split(".");
        } else {
            time= [];
            if (myTimeStr.length === 3) {
                time.push(myTimeStr.substring(0, 1));
                time.push(myTimeStr.substring(1, 3));
            } else {
                time.push(myTimeStr.substring(0, 2));
                time.push(myTimeStr.substring(2, 4));
            }
        }

        let [hour, minute, second] = [0, 0, 0];
        if (time.length === 2) {
            [hour, minute, second] = [zeroPad(time[0], 2), zeroPad(time[1], 2), "00"];
        } else if (time.length === 3) {
            [hour, minute, second] = [zeroPad(time[0], 2), zeroPad(time[1], 2), zeroPad(time[2], 2)];
        }
        if (mode === 0) return `${hour}:${minute}`
        else if (mode === 1) return `${hour}:${minute}:${second}`;
    } else return "";
}

/**
 * @function dateTimeStrConv
 * @description Converts a unix type datetime string to a more readable version according to the
 *                  provided locale. Defaults to 'en'.
 *
 * @param {string} dateTimeStr Unix style date time yyyy-mm-ddThh:MM:sssZ
 * @param {string} locale in which locale the string should be returned. ('en' or 'nl')
 * @returns {string} The converted String
 */
const dateTimeStrConv = function (dateTimeStr, locale = 'en') {
    let [date1, time1] = dateTimeStr.split("T");
    time1 = time1.substring(0, time1.length - 4);
    let [year1, month1, day1] = date1.split("-");
    let result;
    if (locale === 'en'){
        result = `${year1}-${month1}-${day1} ${time1}`;
    } else {
        result = `${day1}-${month1}-${year1} ${time1}`;
    }
    return result;
}

/**
 * @function offsetConv
 * @description Converts an offset string to an amount of seconds to be used in the offset value.
 *              Offsets can be '1h', '20m', '-2.1H', '-30M'. Which will be converted to the right amount of seconds.
 *              All-digit strings will be converted to seconds. (e.g. '3600' will be converted to 3600 seconds)
 *              The function will return 0 if the offset string is not valid.
 *
 * @param {string} offsetStr Offset string to be parsed.
 * @param {number} mode 0 for normal mode (seconds), 1 for minutes, 2 for hours.
 * @returns {number} Offset in seconds.
 */
const offsetConv = function (offsetStr, mode = 0) {
    const reOffset = /^([+-]?\d{0,4}[,.]?\d{0,2})([HhMm]?)$/g;
    const regex = reOffset.exec(offsetStr);
    let [timeOffset, timeType] = [regex[1], regex[2]];
    let timeOffset2;
    if (timeOffset.includes(",")) {
        timeOffset2 = timeOffset.split(",");
        timeOffset = parseFloat(`${timeOffset2[0]}.${timeOffset2[1]}`);
    }
    let seconds = 0;
    if (regex[2]){
        switch (timeType) {
            case 'h':
            case 'H':
                seconds += Math.round(timeOffset * 3600);
                break;
            case 'm':
            case 'M':
                seconds += Math.round(timeOffset * 60);
                break;
            default:
                seconds = 0;
                break;
        }
    } else {
        seconds = parseFloat(timeOffset);
    }
    if (mode === 1) seconds = Math.round(seconds / 60);
    else if (mode === 2) seconds = Math.round(seconds / 3600);
    return seconds;
}

export { dateConv, timeConv, getWeekNumber, timeStrConv, specialDateConv, dateTimeStrConv, offsetConv, paddedDateConv };
